exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blogpreview-js": () => import("./../../../src/pages/blogpreview.js" /* webpackChunkName: "component---src-pages-blogpreview-js" */),
  "component---src-pages-contact-information-js": () => import("./../../../src/pages/contact-information.js" /* webpackChunkName: "component---src-pages-contact-information-js" */),
  "component---src-pages-duration-js": () => import("./../../../src/pages/duration.js" /* webpackChunkName: "component---src-pages-duration-js" */),
  "component---src-pages-get-resume-js": () => import("./../../../src/pages/get-resume.js" /* webpackChunkName: "component---src-pages-get-resume-js" */),
  "component---src-pages-hire-developers-js": () => import("./../../../src/pages/hire-developers.js" /* webpackChunkName: "component---src-pages-hire-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrated-management-system-policy-js": () => import("./../../../src/pages/integrated-management-system-policy.js" /* webpackChunkName: "component---src-pages-integrated-management-system-policy-js" */),
  "component---src-pages-it-professionals-js": () => import("./../../../src/pages/it-professionals.js" /* webpackChunkName: "component---src-pages-it-professionals-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-roles-js": () => import("./../../../src/pages/roles.js" /* webpackChunkName: "component---src-pages-roles-js" */),
  "component---src-pages-start-date-js": () => import("./../../../src/pages/start-date.js" /* webpackChunkName: "component---src-pages-start-date-js" */),
  "component---src-pages-tech-stacks-js": () => import("./../../../src/pages/tech-stacks.js" /* webpackChunkName: "component---src-pages-tech-stacks-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/templates/blogDetailPage.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-podcast-detail-js": () => import("./../../../src/templates/podcast-detail.js" /* webpackChunkName: "component---src-templates-podcast-detail-js" */)
}

